import { React, useContext } from "react";
import { motion } from "framer-motion";
import { OrderContext } from "./order";
import { Link } from "react-router-dom";

const Step4 = () => {
    const [order] = useContext(OrderContext);

    const getTotal = () => {
        let sum = parseFloat(order.step2.formula.price)
        let sumSo = 0
        let sumOp = 0
        order.step3.simple_options.map((item) => {
            sumSo += parseFloat(item.price)
        })
        order.step3.option_pack.map((item) => {
            sumOp += parseFloat(item.price)
        })
        return parseFloat(sum + sumSo + sumOp).toFixed(2)
    }

    return (
        <>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ type: "linear", duration: 0.2 }} className="container bg-white">
                <div className="spacer"></div>
                <div className="grid-2-l pad-b-xl">
                    <div className="grid-item-t">
                        <div className="basic-card bg-blue marg-b-xl">
                            <div className="basic-card-header">
                                <Link to={"/demande/etape-1"} className="icon-small bg-icon-white">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                    </svg>
                                </Link>
                            </div>
                            <div className="basic-card-content">
                                <div className="flex-col-center pad-b-l">
                                    <div className="rounded-card" htmlFor="event">
                                        <div className="rounded-card-img">
                                            <img className="img-cover" src="/images/platine.jpg" alt="Banner" />
                                        </div>
                                        <p className="large-text bold-text white-text">
                                            {order.step1.event.type ? order.step1.event.type.title : ""}
                                        </p>
                                    </div>
                                </div>
                                <ul className="flex-col-start">
                                    <li className="flex-row-start pad-b-m">
                                        <div className="icon-x-small bg-icon-white">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 468 512">
                                                <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                                            </svg>
                                        </div>
                                        <p className="medium-text bold-text white-text pad-l-m">
                                            {order.step1.firstname + " " + order.step1.lastname}
                                        </p>
                                    </li>
                                    <li className="flex-row-start pad-b-m">
                                        <div className="icon-x-small bg-icon-white">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 512">
                                                <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
                                            </svg>
                                        </div>
                                        <p className="medium-text bold-text white-text pad-l-m">
                                            {order.step1.email}
                                        </p>
                                    </li>
                                    <li className="flex-row-start pad-b-m">
                                        <div className="icon-x-small bg-icon-white">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 532 562">
                                                <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                                            </svg>
                                        </div>
                                        <p className="medium-text bold-text white-text pad-l-m">
                                            {order.step1.phone}
                                        </p>
                                    </li>
                                    <li className="flex-row-start pad-b-m">
                                        <div className="icon-x-small bg-icon-white">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                <path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z" />
                                            </svg>
                                        </div>
                                        <p className="medium-text bold-text white-text pad-l-m">
                                            {order.step1.event.date}
                                        </p>
                                    </li>
                                    <li className="flex-row-start pad-b-m">
                                        <div className="icon-x-small bg-icon-white">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 512">
                                                <path d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                                            </svg>
                                        </div>
                                        <p className="medium-text bold-text white-text pad-l-m">
                                            {order.step1.event.start_hour}
                                        </p>
                                    </li>
                                    <li className="flex-row-start pad-b-m">
                                        <div className="icon-x-small bg-icon-white">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562 512">
                                                <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                                            </svg>
                                        </div>
                                        <p className="medium-text bold-text white-text pad-l-m">
                                            {order.step1.event.end_hour}
                                        </p>
                                    </li>
                                    <li className="flex-row-start pad-b-m">
                                        <div className="icon-x-small bg-icon-white">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                <path d="M16 144a144 144 0 1 1 288 0A144 144 0 1 1 16 144zM160 80c8.8 0 16-7.2 16-16s-7.2-16-16-16c-53 0-96 43-96 96c0 8.8 7.2 16 16 16s16-7.2 16-16c0-35.3 28.7-64 64-64zM128 480V317.1c10.4 1.9 21.1 2.9 32 2.9s21.6-1 32-2.9V480c0 17.7-14.3 32-32 32s-32-14.3-32-32z" />
                                            </svg>
                                        </div>
                                        <div className="flex-col-start">
                                            <p className="medium-text bold-text white-text pad-l-m">
                                                {order.step1.event.adress}
                                            </p>
                                            <p className="medium-text bold-text white-text pad-l-m">
                                                {order.step1.event.postal_code +
                                                " " +
                                                order.step1.event.city}
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="grid-item">
                        <div className="flex-col-start full-width">
                            <div className="flex-row-start pad-b-l">
                                <div className="icon-medium bg-icon-black">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                        <path d="M264.5 5.2c14.9-6.9 32.1-6.9 47 0l218.6 101c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 149.8C37.4 145.8 32 137.3 32 128s5.4-17.9 13.9-21.8L264.5 5.2zM476.9 209.6l53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 277.8C37.4 273.8 32 265.3 32 256s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0l152-70.2zm-152 198.2l152-70.2 53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 405.8C37.4 401.8 32 393.3 32 384s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0z" />
                                    </svg>
                                </div>
                                <h2 className="big-text bold-text black-text pad-l-m pad-r-m">
                                    Formule choisie
                                </h2>
                                <Link to={"/demande/etape-2"} className="icon-x-small bg-icon-black">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                    </svg>
                                </Link>
                            </div>
                            <div className="flex-col-start pad-b-xl">
                                <div className="fancy-card-list">
                                    <div className="fancy-card-pres">
                                        <Link to={`/formules/${order.step2.formula.slug}`} className="fancy-card-head fancy-card-head-blue">
                                            <p className="big-text bold-text white-text">{order.step2.formula.pack}</p>
                                        </Link>
                                        <div className="fancy-card-content">
                                            <div className="flex-col-center">
                                            <p className="large-text regular-text blue-text">{order.step2.formula.supp_hours}</p>
                                            </div>
                                            <div className="flex-col-center">
                                                <ul className="fancy-item-list">
                                                    {order.step2.formula ? order.step2.formula.items.map((item, i) => {
                                                        return (
                                                            <li className="fancy-card-item" key={i}>
                                                                <div className="circle-dot circle-blue"></div>
                                                                <p className="large-text regular-text r-black-text pad-l-s">
                                                                    <b>{item.quantity}</b> {item.title}
                                                                </p>
                                                            </li> 
                                                        )
                                                    }) : ""}
                                                </ul>
                                                <Link className="big-text bold-text" to={`/formules/${order.step2.formula.slug}`}>. . .</Link>
                                            </div>
                                        </div>
                                        <div className="fancy-card-footer pad-b-l">
                                            <p className="medium-text regular-text r-black-text">
                                                à partir de
                                            </p>
                                            <p className="big-text bold-text blue-text">{order.step2.formula.price} €</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-col-start">
                                <div className="flex-row-start pad-b-l">
                                    <div className="icon-medium bg-icon-black">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 590 512">
                                            <path d="M0 32C0 14.3 14.3 0 32 0H160c17.7 0 32 14.3 32 32V416c0 53-43 96-96 96s-96-43-96-96V32zM223.6 425.9c.3-3.3 .4-6.6 .4-9.9V154l75.4-75.4c12.5-12.5 32.8-12.5 45.3 0l90.5 90.5c12.5 12.5 12.5 32.8 0 45.3L223.6 425.9zM182.8 512l192-192H480c17.7 0 32 14.3 32 32V480c0 17.7-14.3 32-32 32H182.8zM128 64H64v64h64V64zM64 192v64h64V192H64zM96 440a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"/>
                                        </svg>
                                    </div>
                                    <h2 className="big-text bold-text black-text pad-l-m pad-r-m">
                                        Options choisis
                                    </h2>
                                    <Link
                                        to={"/demande/etape-3"}
                                        className="icon-x-small bg-icon-black"
                                    >
                                        <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                        >
                                        <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                        </svg>
                                    </Link>
                                </div>
                                {order.step3.simple_options.length > 0 ? (
                                    <div className="grid-2-t pad-b-xl">
                                        {order.step3.simple_options.map((item, i) => {
                                            return (
                                            <div className="fancy-small-card" key={i}>
                                                <div className="fancy-small-card-header">
                                                <p className="large-text bold-text r-black-text pad-l-s">{item.quantity}</p>
                                                </div>
                                                <div className="fancy-small-card-content">
                                                <p className="medium-text bold-text r-black-text">
                                                    {item.simple_option.title}
                                                </p>
                                                </div>
                                                <div className="fancy-small-card-footer">
                                                <p className="medium-text bold-text white-text">{item.price} €</p>
                                                </div>
                                            </div>
                                            )
                                        })}
                                    </div>
                                ) : (
                                    <div className="flex-col-center pad-b-xl">
                                        <p className="large-text bold-text center-text black-text">Aucune option individuelle sélectionner.</p>
                                    </div>
                                )}
                                
                            </div>
                            <div className="flex-col-start">
                                <div className="flex-row-start pad-b-l">
                                    <div className="icon-medium bg-icon-black">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                            <path d="M58.9 42.1c3-6.1 9.6-9.6 16.3-8.7L320 64 564.8 33.4c6.7-.8 13.3 2.7 16.3 8.7l41.7 83.4c9 17.9-.6 39.6-19.8 45.1L439.6 217.3c-13.9 4-28.8-1.9-36.2-14.3L320 64 236.6 203c-7.4 12.4-22.3 18.3-36.2 14.3L37.1 170.6c-19.3-5.5-28.8-27.2-19.8-45.1L58.9 42.1zM321.1 128l54.9 91.4c14.9 24.8 44.6 36.6 72.5 28.6L576 211.6v167c0 22-15 41.2-36.4 46.6l-204.1 51c-10.2 2.6-20.9 2.6-31 0l-204.1-51C79 419.7 64 400.5 64 378.5v-167L191.6 248c27.8 8 57.6-3.8 72.5-28.6L318.9 128h2.2z"/>
                                        </svg>
                                    </div>
                                    <h2 className="big-text bold-text black-text pad-l-m pad-r-m">
                                        Packs choisis
                                    </h2>
                                    <Link to={"/demande/etape-3"} className="icon-x-small bg-icon-black">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                        </svg>
                                    </Link>
                                </div>
                                {order.step3.option_pack.length > 0 ? (
                                    <div className="grid-2-t pad-b-xl">
                                        {order.step3.option_pack.map((option_pack, i) => {
                                            return (
                                                <div key={i} className="fancy-card-simple-od">
                                                    <div className="fancy-card-simple-header">
                                                        <div className="flex-col-center pad-b-xl">
                                                            <p className="big-text bold-text blue-text">{option_pack.title}</p>
                                                        </div>
                                                    </div>
                                                    <div className="fancy-card-simple-content pad-b-xl">
                                                        <div className="flex-col-start">
                                                            <ul className="fancy-item-list">
                                                                {option_pack.items.map((item, i) => {
                                                                    return (
                                                                        <li className="fancy-card-item" key={i}>
                                                                            <div className="circle-dot circle-blue"></div>
                                                                            <p className="large-text regular-text r-black-text pad-l-s">
                                                                                <b>{item.quantity}</b> {item.simple_option.title}
                                                                            </p>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="fancy-card-simple-footer pad-b-xl">
                                                        <p className="big-text bold-text blue-text">{option_pack.price} €</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                ) : (
                                    <div className="flex-col-center pad-b-xl">
                                        <p className="large-text bold-text center-text black-text">Aucun pack d'option sélectionner.</p>
                                    </div>
                                )}
                                <div className="flow-card bg-blue marg-b-xl">
                                    <div className="flex-row-between">
                                        <div className="flex-row-center">
                                        <p className="large-text regular-text white-text pad-r-s">Eléments</p>
                                        <div className="circle circle-white">
                                            <p className="medium-text bold-text blue-text">{1 + order.step3.simple_options.length + order.step3.option_pack.length}</p>
                                        </div>
                                        </div>
                                        <div className="flex-row-start">
                                        <p className="large-text regular-text white-text pad-r-s">Totale</p>
                                        <p className="large-text bold-text white-text">{getTotal()} €</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-row-between pad-b-xl">
                    <a href={"/demande/etape-3"} className="btn btn-default btn-large btn-cream">
                        Précédent
                    </a>
                    <button type="submit" className="btn btn-default btn-large btn-current">
                        Enregistrer
                    </button>
                </div>
                <div className="spacer"></div>
            </motion.div>
        </>
    );
};

export default Step4;
