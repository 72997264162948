import React, { useCallback, useContext, useEffect, useState } from "react"
import { useOutletContext } from 'react-router-dom';
import { motion, /* useScroll */ } from "framer-motion";
import { OrderContext } from "./order";
import axiosProvider from "../../core/axios";
import { toast } from "react-toastify";

const Step1 = () => {
    const [order, setOrder, setError] = useContext(OrderContext);
    const [data, setData] = useState(null);
    const [saveOrder] = useOutletContext();

    const fetchData = useCallback(async () => {
        await axiosProvider.get("/shop/event-type/").then((response) => {
            setData(response.data)
        }).catch((error) => {
            setError({
                error : "Erreur de récupération de données", 
                message:"Une erreur c'est produite lors de la récupération des données."
            })
        });
    }, [setError]);

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const checkBox = (item) => {
        if(item.uuid === order.step1.event.type.uuid){
            return order.step1.event.type.title
        }
        return ""
    }
    const checkClass = (item) => {
        if(item.uuid === order.step1.event.type.uuid){
            return "rounded-card-img rounded-card-img-selected"
        }
        return "rounded-card-img"
    }

    const checkText = (item) => {
        if(item.uuid === order.step1.event.type.uuid){
            return "rounded-card-text-selected"
        }
        return "rounded-card-text"
    }

    const checkStep = (order) => {
        const requiredFields = [
            "lastname",
            "firstname",
            "email",
            "phone",
            "event.adress",
            "event.type",
            "event.postal_code",
            "event.city",
            "event.date",
            "event.start_hour",
            "event.end_hour"
        ];
    
        for (let field of requiredFields) {
            const value = field.split('.').reduce((obj, key) => obj?.[key], order.step1);
            if (!value || (typeof value === "string" && value.trim() === "") || value.length === 0) {
                return false;
            }
        }
        return true;
    };

    const handleNext = () => {
        if (checkStep(order)) {
            saveOrder(1);
        } else {
            toast.error("Étape 1 incomplète : Veuillez remplir tous les champs obligatoires.");
            window.scrollTo(0, 0)
        }
    }

    return (
        <>
            {data ? (
                <>
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ type: "linear", duration: 0.2 }} className="container bg-white">
                    <div className="spacer"></div>
                        <h2 className="big-text bold-text black-text pad-b-l">Informations générales</h2>
                        <div className="grid-2 pad-b-xl">
                        <div className="grid-item">
                            <input className="input input-medium input-bordered input-border-current" type="text" name="lastname" placeholder="Nom" value={order.step1.lastname} onChange={(e) => setOrder({...order, step1: {...order.step1, lastname: e.target.value}})} required />
                        </div>
                        <div className="grid-item">
                            <input className="input input-medium input-bordered input-border-current" type="text" name="firstname" placeholder="Prénom" value={order.step1.firstname} onChange={(e) => setOrder({...order, step1: {...order.step1, firstname: e.target.value}})} required />
                        </div>
                        <div className="grid-item">
                            <input className="input input-medium input-bordered input-border-current" type="email" name="email" placeholder="Email" value={order.step1.email} onChange={(e) => setOrder({...order, step1: {...order.step1, email: e.target.value}})} required />
                        </div>
                        <div className="grid-item">
                            <input className="input input-medium input-bordered input-border-current" type="tel" name="phone" placeholder="Numéro de téléphone" value={order.step1.phone} onChange={(e) => setOrder({...order, step1: {...order.step1, phone: e.target.value}})} required />
                        </div>
                        </div>
                        <h2 className="big-text bold-text black-text pad-b-l">Type d'évènement</h2>
                        <div className="grid-2-4 pad-b-l">
                            {data.map((item, i) => {
                                return (
                                    <div className="grid-item" key={i}>
                                        <label className="rounded-card" htmlFor={item.title}>
                                            <div className={checkClass(item)}>
                                                <img className="img-cover" src={item.picture} alt={item.title} />
                                            </div>
                                            <p className={checkText(item)}>{item.title}</p>
                                        </label>
                                        <input className="rounded-card-selector" type="radio" id={item.title} name="type" value={checkBox(item)} onChange={() => setOrder({...order, step1: {...order.step1, event: {...order.step1.event, type: item}}})} />
                                    </div>
                                )
                            })}
                        </div>
                        <h2 className="big-text bold-text black-text pad-b-l">Déroulé de l'évènement</h2>
                        <div className="grid-2 pad-b-m">
                        <div className="grid-item">
                            <input className="input input-medium input-bordered input-border-current" type="text" name="event_location" placeholder="Adresse de l'évènement" value={order.step1.event.adress} onChange={(e) => setOrder({...order, step1: {...order.step1, event: {...order.step1.event, adress: e.target.value}}})} required />
                        </div>
                        <div className="grid-item">
                            <input className="input input-medium input-bordered input-border-current" type="text" name="postal_code" placeholder="Code postale" value={order.step1.event.postal_code} onChange={(e) => setOrder({...order, step1: {...order.step1, event: {...order.step1.event, postal_code: e.target.value}}})} required />
                        </div>
                        <div className="grid-item">
                            <input className="input input-medium input-bordered input-border-current" type="text" name="city" placeholder="Ville" value={order.step1.event.city} onChange={(e) => setOrder({...order, step1: {...order.step1, event: {...order.step1.event, city: e.target.value}}})} required />
                        </div>
                        <div className="grid-item">
                            <div className="flex-row-between full-width">
                            <label htmlFor="event_date" className="medium-text regular-text black-text pad-r-m">Date</label>
                            <input className="input input-date input-bordered input-border-current" type="date" name="event_date" placeholder="Date de l'évènement" value={order.step1.event.date} onChange={(e) => setOrder({...order, step1: {...order.step1, event: {...order.step1.event, date: e.target.value}}})} required />
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="flex-row-between full-width">
                            <label htmlFor="event_date" className="medium-text regular-text black-text pad-r-m">Heure de début</label>
                            <input className="input input-time input-bordered input-border-current" type="time" name="event_start_hour" placeholder="Heure de début" value={order.step1.event.start_hour} onChange={(e) => setOrder({...order, step1: {...order.step1, event: {...order.step1.event, start_hour: e.target.value}}})} required />
                            </div>
                        </div>
                        <div className="grid-item">
                            <div className="flex-row-between full-width">
                            <label htmlFor="event_date" className="medium-text regular-text black-text pad-r-m">Heure de fin</label>
                            <input className="input input-time input-bordered input-border-current" type="time" name="event_end_hour" placeholder="Heure de fin" value={order.step1.event.end_hour} onChange={(e) => setOrder({...order, step1: {...order.step1, event: {...order.step1.event, end_hour: e.target.value}}})}  required />
                            </div>
                        </div>
                        </div>
                        <textarea className="input input-textarea input-bordered input-border-current marg-b-xl" rows="5" name="supp_info" placeholder="Informations supplémentaires (facultatif)" value={order.step1.event.sup_infos} onChange={(e) => setOrder({...order, step1: {...order.step1, event: {...order.step1.event, sup_infos: e.target.value}}})}  />
                        <div className="flex-row-between">
                        <div className="clearfix"></div>
                        <div className="btn btn-default btn-large btn-current" onClick={() => handleNext()}>Suivant</div>
                        </div>
                    <div className="spacer"></div>
                    </motion.div>
                </>

            ):(
                <>
                    <div className="container-primary bg-white">
                        <div className="flex-full-center full-height">
                            <div className="icon-big">
                                <motion.svg xmlns="http://www.w3.org/2000/svg" 
                                viewBox="0 0 422 422"
                                animate={{rotate:[0, 360] }}
                                transition={{
                                    type: "linear",
                                    duration: 0.5, 
                                    repeat: Infinity
                                }}>
                                    <g id="loading_icon" transform="translate(4730 -3101)">
                                        <path className="loading" id="Subtraction_2" data-name="Subtraction 2" d="M436.543,212.707h0l-72.556-.1c.008-.575.012-1.179.012-1.795A128.8,128.8,0,0,0,275.063,88.184V13.247l.422.086.134.027a200.468,200.468,0,0,1,72.07,30.326,202.154,202.154,0,0,1,73.023,88.677A200.476,200.476,0,0,1,432.457,170.2a203.034,203.034,0,0,1,4.1,40.62c0,.62,0,1.255-.009,1.889Z" transform="translate(-4744.552 3094.183)" />
                                        <circle id="Ellipse_25" data-name="Ellipse 25" cx="211" cy="211" r="211" transform="translate(-4730 3101)" fill="none"/>
                                    </g>
                                </motion.svg>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default Step1