import { useState, useEffect } from 'react';

const ResponsivePosition = () => {
    const [position, setPosition] = useState({ left: '10vw', top: '20vh', right: '10vw' });

    useEffect(() => {
        const updatePosition = () => {
            let left = position.left
            let top = position.top
            let right = position.right
            
            switch(true){
                case window.innerWidth <= 390:
                    left =  '-30vh'
                    right = '-35vh'
                    break
                case window.innerWidth <= 767:
                    left =  '-35vh'
                    right = '-35vh'
                    break
                case window.innerWidth <= 950:
                    left =  '20vh'
                    right = '20vh'
                    break
                case window.innerWidth <= 1070:
                    left =  '30vh'
                    right = '30vh'
                    break
                case window.innerWidth <= 1165:
                    left =  '0px'
                    right = '0px'
                    break
                case window.innerWidth <= 1730:
                    left =  '100vh'
                    right = '100vh'
                    break
                case window.innerWidth <= 1920:
                    left =  '120vh'
                    right = '120vh'
                    break
                default:
                    left =  position.left
                    right = position.right
                    break
            }

            switch(true){
                case window.innerHeight <= 750:
                    top = "0px"    
                    break
                case window.innerHeight <= 850:
                    top = "10vh"    
                    break
                case window.innerHeight <= 950:
                    top = "20vh"    
                    break
                case window.innerHeight <= 1000:
                    top = "20vh"    
                    break
                case window.innerHeight <= 1030:
                    top = "25vh"
                    break
                case window.innerHeight <= 1080:
                    top = "30vh"    
                    break
                default:
                    top = position.top
                    break
            }

            setPosition({ left: left, top: top, right: right });
        };

        updatePosition();
        window.addEventListener('resize', updatePosition);

        return () => window.removeEventListener('resize', updatePosition);
    }, [position.left, position.right, position.top]);

    return position;
}

export default ResponsivePosition