import { createContext, useEffect, useState } from "react";
import { Routes, Route, useLocation } from 'react-router-dom'
import './assets/css/main.css'
import Header from "./layers/header";
import { motion, AnimatePresence } from 'framer-motion'
import Home from "./pages/home/home";
import Formulas from "./pages/formula/formulas";
import FormulaDetail from "./pages/formula/formulaDetail";
import Footer from "./layers/footer";
import Galery from "./pages/galery/galery";
import NotFound from "./layers/notFound";
import Order from "./pages/order/order";
// import Articles from "./pages/article/articles";
// import ArticleDetail from "./pages/article/articleDetail";
import LegalMention from "./pages/legal/legalMention";
import ConfidentialPolicy from "./pages/legal/confidentialPolicy";
import About from "./pages/about/about";
import Step1 from "./pages/order/step1";
import Step2 from "./pages/order/step2";
import Step3 from "./pages/order/step3";
import Step4 from "./pages/order/step4";
import Step5 from "./pages/order/step5";

export const ThemeContext = createContext(null)

function App() {
  const [theme, setTheme] = useState("light")
  const location = useLocation()
  
  useEffect(() => {
    if(localStorage.getItem('theme')){
        setTheme(localStorage.getItem('theme'))
    }
  }, [])

  const toggleTheme = () => {
    if(theme === "light"){
      setTheme("dark")
      updateStorage("dark")
    }else{
      setTheme("light")
      updateStorage("light")
    }
  }

  const updateStorage = (n_theme) => {
    localStorage.setItem('theme', n_theme);
  }
  
  return (
    <ThemeContext.Provider value={{theme, toggleTheme}}>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ type: "linear", duration: 0.2 }} className="App" id={theme}>
        <Header />
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route exact path="/" element={<Home />} />
                <Route exact path="*" element={<NotFound />} />
                <Route exact path="/404" element={<NotFound />} />
                <Route exact path="/a-propos" element={<About />} />
                <Route exact path="/galerie" element={<Galery />} />
                <Route exact path="/mentions-legals" element={<LegalMention />} />
                <Route exact path="/politique-confidentialites" element={<ConfidentialPolicy />} />
                <Route path="/demande/" element={<Order />} >
                  <Route exact path="/demande/etape-1" element={<Step1 />} />
                  <Route exact path="/demande/etape-2" element={<Step2 />} />
                  <Route exact path="/demande/etape-3" element={<Step3 />} />
                  <Route exact path="/demande/etape-4" element={<Step4 />} />
                  <Route exact path="/demande/etape-5" element={<Step5 />} />
                </Route>
                {/* <Route exact path="/articles" element={<Articles />} />
                <Route exact path="/articles/:slug" element={<ArticleDetail />} />
                <Route exact path="/articles/categories" element={<ArticleDetail />} />
                <Route exact path="/articles/categories/:slug" element={<ArticleDetail />} /> */}
                <Route exact path="/formules" element={<Formulas />} />
                <Route exact path="/formules/:slug" element={<FormulaDetail />} />
            </Routes>
        </AnimatePresence>
        <Footer />
      </motion.div>
    </ThemeContext.Provider>
  );
}

export default App;
